import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/buildhome/repo/node_modules/@primer/gatsby-theme-doctocat/src/components/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <pre><code parentName="pre" {...{}}>{`{{ states | map(attribute='domain') |list | unique | list}}
`}</code></pre>
    <p>{`From: `}<a parentName="p" {...{
        "href": "https://github.com/skalavala/smarthome/tree/master/jinja_helpers"
      }}>{`https://github.com/skalavala/smarthome/tree/master/jinja_helpers`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      